import { yupResolver } from '@hookform/resolvers/yup';
import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import {
  Control,
  FieldValues,
  FormState,
  useForm,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
  UseFormSetError,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import api from '~/services/api';
import { errorHandler } from '~/utils/ErrorHandler';
import {
  Fornecedor,
  Perfil,
  Loja,
  Produto,
  Critica,
  Finalizadora,
  Condicao,
  Serie,
  Especie,
  Select2,
  SelectEspecie,
  SelectPerfil,
  SelectLoja,
  FormItemDisabled,
  Parcela,
  ValidacaoProdutorProps,
} from './protocols';
import {
  schemaFormItem,
  schemaFormNfe,
  schemaFormParcela,
} from './validations';
import useAuth from '~/hooks/useAuth';
import clearForm from './screens/Financeiro/FormParcelas/clearForm.json';
import { MasterDetailProps } from '~/utils/masterDetail';
import Swal from 'sweetalert2';

import nfe from './defaultData/nfe.json';
import itens from './defaultData/itens.json';

type UseFormProps = {
  register: UseFormRegister<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  formState: FormState<FieldValues>;
  control: Control<FieldValues, any>;
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  reset: UseFormReset<FieldValues>;
  setFocus: UseFormSetFocus<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  setError: UseFormSetError<FieldValues>;
};

type EntradaNFEContextData = {
  codSeqNf: number | undefined;
  setCodSeqNf: (codSeqNf: number | undefined) => void;
  codXml: number | undefined;
  setCodXml: (codSeqNf: number | undefined) => void;
  numChaveAcesso: string;
  setNumChaveAcesso: (numChaveAcesso: string) => void;
  currentTab: any;
  loadingTable: boolean;
  validadorProdutorRural: (data: any) => void;
  validacaoProdutor: (
    validacaoProdutor: ValidacaoProdutorProps,
  ) => Promise<boolean>;
  setLoadingTable: (loadingTable: boolean) => void;
  setCurrentTab: (currentTab: any) => void;
  lojasAtivas: SelectLoja[];
  loja: Loja;
  changeLoja: (data: any) => void;
  produto: Produto;
  setProduto: (value: React.SetStateAction<Produto>) => void;
  // changeProduto: (data: any) => void;
  perfil: Perfil;
  setPerfil: (perfil: Perfil) => void;
  changePerfil: (data: any) => void;
  fornecedor: Fornecedor;
  changeFornecedor: (data: any) => void;
  setCriticas: (value: React.SetStateAction<Critica[]>) => void;
  criticas: Critica[];
  initParcela: boolean;
  setInitParcela: (value: React.SetStateAction<boolean>) => void;
  produtos: Produto[];
  setProdutos: (value: React.SetStateAction<Produto[]>) => void;
  setSerie: (value: React.SetStateAction<any>) => void;
  serie: Serie;
  formItem: UseFormProps;
  formParcela: UseFormProps;
  formNfe: UseFormProps;
  setProdutoModalNaoCalcBc: (value: React.SetStateAction<Produto>) => void;
  produtoModalNaoCalcBc: Produto;
  setShowModalNaoCalcBc: (value: React.SetStateAction<boolean>) => void;
  showModalNaoCalcBc: boolean;
  canUpdate: boolean;
  setCanUpdate: (value: React.SetStateAction<boolean>) => void;
  resetForms: () => void;
  setIsUpdate: (value: React.SetStateAction<boolean>) => void;
  isUpdate: boolean;
  condicoesPagamento: Select2[];
  finalizadoras: Select2[];
  perfis: SelectPerfil[];
  especies: SelectEspecie[];
  lojas: SelectLoja[];
  disabledFormItens: FormItemDisabled;
  setDisabledFormItens: (value: React.SetStateAction<FormItemDisabled>) => void;
  masterDetail: MasterDetailProps[];
  setMasterDetail: (masterDetail: MasterDetailProps[]) => void;
  uuidParcela: { uuid: string; cod_parcela: number | undefined } | undefined;
  setUuidParcela: (
    item: { uuid: string; cod_parcela: number | undefined } | undefined,
  ) => void;
  uuidItens: { uuid: string; cod_seq_nf_item: number | undefined } | undefined;
  setUuidItens: (
    item: { uuid: string; cod_seq_nf_item: number | undefined } | undefined,
  ) => void;
  parcelas: Parcela[];
  setParcelas: (parcelas: Parcela[]) => void;
  xmlImportado: boolean;
  setXmlImportado: React.Dispatch<React.SetStateAction<boolean>>;
  showFormItens: string;
  setShowFormItens: React.Dispatch<React.SetStateAction<string>>;
  transmissao: boolean;
  setTransmissao: React.Dispatch<React.SetStateAction<boolean>>;
  oldCodPessoa: number;
  setOldCodPessoa: React.Dispatch<React.SetStateAction<number>>;
  blockSerieNF: boolean;
  setBlockSerieNF: React.Dispatch<React.SetStateAction<boolean>>;
  setSubTotais: React.Dispatch<React.SetStateAction<any>>;
  subTotais: any;
};

export const EntradaNFEContext = createContext({} as EntradaNFEContextData);

interface EntradaNFEContextProviderProps {
  children: ReactNode;
}

export function EntradaNFEContextProvider({
  children,
}: EntradaNFEContextProviderProps) {
  const [codSeqNf, setCodSeqNf] = useState<number | undefined>(undefined);
  const [codXml, setCodXml] = useState<number | undefined>(undefined);
  const [numChaveAcesso, setNumChaveAcesso] = useState<string>('');
  const [loadingTable, setLoadingTable] = useState(false);
  const [currentTab, setCurrentTab] = useState<any>('nfe');
  const { user } = useAuth();
  const [criticas, setCriticas] = useState<Critica[]>([]);
  const [initParcela, setInitParcela] = useState(false);
  const [subTotais, setSubTotais] = useState({});
  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [xmlImportado, setXmlImportado] = useState<boolean>(false);

  const [produtoModalNaoCalcBc, setProdutoModalNaoCalcBc] = useState<Produto>(
    {} as Produto,
  );

  const [showModalNaoCalcBc, setShowModalNaoCalcBc] = useState(false);

  const [isUpdate, setIsUpdate] = useState(false);
  const [canUpdate, setCanUpdate] = useState(true);

  const masterDetailDefault = [
    {
      obj_name: 'parcelas',
      pk_fields: ['cod_parcela'],
      itens: {
        insert: [],
        update: [],
        delete: [],
      },
    },
    {
      obj_name: 'nf_itens',
      pk_fields: ['cod_seq_nf_item'],
      itens: {
        insert: [],
        update: [],
        delete: [],
      },
    },
  ];
  const [masterDetail, setMasterDetail] =
    useState<MasterDetailProps[]>(masterDetailDefault);
  const [uuidParcela, setUuidParcela] = useState<{
    uuid: string;
    cod_parcela: number | undefined;
  }>();
  const [uuidItens, setUuidItens] = useState<{
    uuid: string;
    cod_seq_nf_item: number | undefined;
  }>();
  const [parcelas, setParcelas] = useState<Parcela[]>([]);

  const [showFormItens, setShowFormItens] = useState('0');

  const [oldCodPessoa, setOldCodPessoa] = useState(0);

  const {
    register: registerFormNfe,
    getValues: getValuesFormNfe,
    handleSubmit: handleSubmitFormNfe,
    formState: formStateFormNfe,
    control: controlFormNfe,
    setValue: setValueFormNfe,
    watch: watchFormNfe,
    reset: resetFormNfe,
    setFocus: setFocusFormNfe,
    clearErrors: clearErrorsFormNfe,
    setError: setErrorFormNfe,
  } = useForm({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    resolver: yupResolver(schemaFormNfe),
  });

  // useEffect(() => {
  //   (async () => {
  //     const errors: any = Object.keys(formStateFormNfe?.errors);
  //     // eslint-disable-next-line no-restricted-syntax
  //     for await (const error of errors) {
  //       if (error.indexOf('produtor') !== -1) setCurrentTab('produtor');
  //     }
  //   })();
  // }, [formStateFormNfe?.errors]);

  const formNfe: UseFormProps = {
    register: registerFormNfe,
    getValues: getValuesFormNfe,
    handleSubmit: handleSubmitFormNfe,
    formState: formStateFormNfe,
    control: controlFormNfe,
    setValue: setValueFormNfe,
    watch: watchFormNfe,
    reset: resetFormNfe,
    setFocus: setFocusFormNfe,
    clearErrors: clearErrorsFormNfe,
    setError: setErrorFormNfe,
  };

  const watchLoja = watchFormNfe('cod_loja');
  const watchPessoa = watchFormNfe('cod_pessoa');

  useEffect(() => {
    if (watchLoja && watchPessoa) {
      if (watchLoja.uf !== watchPessoa.des_uf) {
        getPerfis(watchLoja.cod_loja, true);
      } else {
        getPerfis(watchLoja.cod_loja, false);
      }
    }
  }, [watchLoja, watchPessoa]);

  useEffect(() => {
    resetFormNfe({
      parcelas: [],
    });
  }, []);

  const {
    register: registerFormItem,
    getValues: getValuesFormItem,
    handleSubmit: handleSubmitFormItem,
    formState: formStateItem,
    control: controlFormItem,
    setValue: setValueFormItem,
    watch: watchFormItem,
    reset: resetFormItem,
    setFocus: setFocusFormItem,
    clearErrors: clearErrorsFormItem,
    setError: setErrorFormNfeItem,
  } = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(schemaFormItem),
  });

  const formItem: UseFormProps = {
    register: registerFormItem,
    getValues: getValuesFormItem,
    handleSubmit: handleSubmitFormItem,
    formState: formStateItem,
    control: controlFormItem,
    setValue: setValueFormItem,
    watch: watchFormItem,
    reset: resetFormItem,
    setFocus: setFocusFormItem,
    clearErrors: clearErrorsFormItem,
    setError: setErrorFormNfeItem,
  };

  const {
    register: registerFormParcela,
    handleSubmit: handleSubmitFormParcela,
    control: controlFormParcela,
    reset: resetFormParcela,
    setValue: setValueFormParcela,
    getValues: getValuesFormParcela,
    watch: watchFormParcela,
    formState: formStateParcela,
    setFocus: setFocusFormParcela,
    clearErrors: clearErrorsFormParcela,
    setError: setErrorFormParcela,
  } = useForm({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    resolver: yupResolver(schemaFormParcela),
  });

  const formParcela: UseFormProps = {
    register: registerFormParcela,
    getValues: getValuesFormParcela,
    handleSubmit: handleSubmitFormParcela,
    formState: formStateParcela,
    control: controlFormParcela,
    setValue: setValueFormParcela,
    watch: watchFormParcela,
    reset: resetFormParcela,
    setFocus: setFocusFormParcela,
    clearErrors: clearErrorsFormParcela,
    setError: setErrorFormParcela,
  };

  /**
   * Form Entrada NFe
   */

  /**
   * Funções
   */

  const [loja, setLoja] = useState({} as Loja);
  function changeLoja(data: any) {
    setLoja(data);
  }

  const [produto, setProduto] = useState({} as Produto);

  const [perfil, setPerfil] = useState({} as Perfil);
  function changePerfil(data: Perfil) {
    if (data.tipo_emissao === 1) {
      setValueFormNfe('num_nf', '0');
    }
    if (data.tipo_operacao === 0 && data.tipo_emissao === 1) {
      setValueFormNfe('des_especie', {
        value: 33,
        label: 'NFE - NOTA FISCAL ELETRÔNICA',
        cod_doc_fiscal: '55',
        des_doc_fiscal: 'NOTA FISCAL ELETRÔNICA',
        des_especie: 'NFE',
        num_modelo_fiscal: '',
      });
    } else {
      setValueFormNfe('des_especie', undefined);
    }
    setValueFormNfe('cod_serie', '');
    setPerfil(data);
    validadorProdutorRural({
      fornecedor,
      perfil: data,
    });
  }

  const [serie, setSerie] = useState({} as Serie);

  const [fornecedor, setFornecedor] = useState({} as Fornecedor);
  function changeFornecedor(data: Fornecedor) {
    setFornecedor(data);
  }

  const [condicoesPagamento, setCondicoesPagamento] = useState<Select2[]>([]);
  const [finalizadoras, setFinalizadoras] = useState<Select2[]>([]);
  const [perfis, setPerfis] = useState<SelectPerfil[]>([]);
  const [especies, setEspecies] = useState<SelectEspecie[]>([]);

  const [transmissao, setTransmissao] = useState<boolean>(false);
  const [blockSerieNF, setBlockSerieNF] = useState<boolean>(false);

  async function getCondicoesPagamento() {
    try {
      const res = await api.get('/condicao');
      const { data, success, message } = res.data;
      if (!success) {
        throw new Error(message);
      }
      const regs = data.map((item: Condicao) => {
        return {
          value: item.cod_condicao,
          label: `${item.des_definicao} - ${item.des_condicao}`,
        };
      });
      setCondicoesPagamento(regs);
    } catch (e: any) {
      errorHandler(e);
    }
  }

  async function getFinalizadoras(codLoja: number) {
    try {
      const res = await api.get('/finalizadora/ativas', {
        params: {
          codLoja,
        },
      });
      const { data, success, message } = res.data;
      if (!success) {
        throw new Error(message);
      }
      const regs = data.map((item: Finalizadora) => {
        return {
          value: item.cod_finalizadora,
          label: item.des_finalizadora,
        };
      });
      setFinalizadoras(regs);
    } catch (e: any) {
      errorHandler(e);
    }
  }

  async function getPerfis(codLoja: number, isInterestadual: boolean) {
    try {
      const res = await api.get('/entrada-nfe/perfil', {
        params: {
          codLoja: codLoja || watchLoja.cod_loja,
          isInterestadual:
            isInterestadual || watchLoja.uf !== watchPessoa.des_uf,
        },
      });
      const { data, success, message } = res.data;

      if (!success) {
        throw new Error(message);
      }
      const regs = data.map((item: any) => {
        return {
          value: item.cod_perfil,
          flg_gera_financeiro: item.flg_gera_financeiro,
          label: `${item.descricao} - Tipo: ${item.des_tipo_nf} | Un Emb: ${item.des_tipo_unidade} | Emiss: ${item.des_tipo_emissao}`,
          cod_perfil: item.cod_perfil,
          des_perfil: item.des_perfil,
          tipo_nf: item.tipo_nf,
          cod_categoria: item.cod_categoria || null,
          cod_cc: item.cod_cc || null,
          tipo_unidade: item.tipo_unidade,
          tipo_emissao: item.tipo_emissao,
          tipo_operacao: item.tipo_operacao,
        };
      });
      setPerfis(regs);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  function validadorProdutorRural(data: any) {
    if (
      canUpdate &&
      data?.fornecedor?.flg_produtor_rural &&
      data?.perfil?.tipo_emissao === 0 &&
      codXml === undefined
    ) {
      Swal.fire({
        text: 'Para NF de Produtor Rural, Perfil deve estar marcado como Emissão Própria. \n Deseja continuar assim mesmo?',
        showCancelButton: true,
        icon: 'warning',
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        const dismiss: any = result.dismiss || '';
        if (result.isDismissed && dismiss === 'cancel') {
          setPerfil({} as Perfil);
          setValueFormNfe('cod_perfil', '');
        }
      });
    }
  }

  async function validacaoProdutor({
    cod_pessoa,
    tipo_nf,
    tipo_operacao,
    cod_loja,
    num_nf_produtor,
    num_serie_produtor,
    num_cnpj_cpf_produtor,
    des_especie_produtor,
    flg_produtor_rural,
  }: ValidacaoProdutorProps): Promise<boolean> {
    let validacao = true;
    if (
      flg_produtor_rural &&
      num_nf_produtor !== '' &&
      num_serie_produtor !== '' &&
      num_cnpj_cpf_produtor !== '' &&
      des_especie_produtor !== ''
    ) {
      const reg = await api.post('/entrada-nfe/validacao-produtor', {
        cod_pessoa,
        tipo_nf,
        tipo_operacao,
        cod_loja,
        num_nf_produtor,
        num_serie_produtor,
        num_cnpj_cpf_produtor,
        des_especie_produtor,
      });
      const { success, data } = reg.data;

      if (success) {
        if (data?.num_reg > 0) {
          validacao = false;
          Swal.fire({
            title: 'Registro NF Produtor já gravado nas Tabelas Fiscais.',
            text: 'Já consta um documento vigente para este parceiro com esse mesmo número de NF, série e espécie de produtor. \n Certifique-se se não se trata de uma duplicidade.',
            icon: 'warning',
            confirmButtonColor: '#8850BF',
            confirmButtonText: 'OK',
          });
          setCurrentTab('produtor');
        }
      }
    }
    return validacao;
  }

  async function getEspecies() {
    const res = await api.get('/entrada-nfe/especies');
    const { data, success, message } = res.data;
    if (!success) {
      throw new Error(message);
    }
    const regs = data.map((item: Especie) => {
      return {
        value: item.cod_especie,
        label: `${item.des_especie} - ${item.des_doc_fiscal}`,
        ...item,
      };
    });
    setEspecies(regs);
  }

  const [lojas, setLojas] = useState<SelectLoja[]>([]);
  const [lojasAtivas, setLojasAtivas] = useState<SelectLoja[]>([]);

  async function getLojas() {
    const res = await api.get('/lojas/usuario');
    const { data } = res.data;

    const createOption = (reg: any) => {
      return {
        label:
          reg.cod_loja < 10
            ? `0${reg.cod_loja} - ${reg.des_loja} (${reg.des_cidade})`
            : `${reg.cod_loja} - ${reg.des_loja} (${reg.des_cidade})`,
        value: reg.cod_loja,
        des_loja: reg.des_loja,
        uf: reg.des_uf,
        cod_loja: reg.cod_loja,
        tipo_regime: reg.tipo_regime,
        ...reg,
      };
    };

    const regsAtivos = data.flatMap((reg: any) => {
      if (reg.flg_inativa !== true) {
        return [createOption(reg)];
      }
      return [];
    });

    const regs = data.map((reg: any) => {
      return createOption(reg);
    });

    setLojasAtivas(regsAtivos);
    setLojas(regs);
  }

  useEffect(() => {
    if (watchLoja) {
      getFinalizadoras(watchLoja.value);
    }
  }, [watchLoja]);

  useEffect(() => {
    getCondicoesPagamento();
    getEspecies();
    getLojas();
  }, []);

  const disabledFormItensInitialState: FormItemDisabled = {
    isDisabled: true,
    placeholder: 'É necessário selecionar o Fornecedor',
  };
  const [disabledFormItens, setDisabledFormItens] = useState<FormItemDisabled>(
    disabledFormItensInitialState,
  );

  const resetLoja = useCallback(async () => {
    if (user.loja) {
      const { data } = await api.get(`/lojas/${user.loja}`);
      if (data.success) {
        const objLoja: any = {
          uf: data.data.des_uf,
          cod_loja: data.data.cod_loja,
          tipo_regime: data.data.tipo_regime,
          des_cidade: data.data.des_cidade,
          des_loja: data.data.des_loja,
          des_uf: data.data.des_uf,
          value: data.data.cod_loja,
        };
        setValueFormNfe('cod_loja', objLoja);
        setLoja(objLoja);
      }
    }
  }, [setValueFormNfe, user.loja]);

  function resetForms() {
    setCodSeqNf(undefined);
    setCodXml(undefined);
    setNumChaveAcesso('');
    setLoadingTable(false);
    resetFormParcela(clearForm);
    setUuidParcela(undefined);
    setUuidItens(undefined);
    setMasterDetail(masterDetailDefault);
    setCriticas([]);
    setCurrentTab('nfe');
    setProduto({} as Produto);
    resetFormNfe(nfe);
    setValueFormNfe('flg_confirmar', false);
    resetFormItem(itens);
    setProdutos([]);
    setParcelas([]);
    setIsUpdate(false);
    setCanUpdate(true);
    resetLoja();
    setProduto({} as Produto);
    setFornecedor({} as Fornecedor);
    setDisabledFormItens(disabledFormItensInitialState);
    setXmlImportado(false);
    setShowFormItens('0');
    setTransmissao(false);
    setOldCodPessoa(0);
    setSubTotais({});
  }

  useEffect(() => {
    resetLoja();
  }, [user, resetLoja]);

  return (
    <EntradaNFEContext.Provider
      value={{
        codSeqNf,
        setCodSeqNf,
        codXml,
        setCodXml,
        numChaveAcesso,
        validacaoProdutor,
        setNumChaveAcesso,
        loadingTable,
        setLoadingTable,
        currentTab,
        setCurrentTab,
        lojasAtivas,
        loja,
        changeLoja,
        produto,
        setProduto,
        perfil,
        setPerfil,
        changePerfil,
        fornecedor,
        changeFornecedor,
        validadorProdutorRural,
        setCriticas,
        criticas,
        initParcela,
        setInitParcela,
        produtos,
        setProdutos,
        setSerie,
        serie,
        formItem,
        formParcela,
        formNfe,
        setProdutoModalNaoCalcBc,
        produtoModalNaoCalcBc,
        setShowModalNaoCalcBc,
        showModalNaoCalcBc,
        canUpdate,
        setCanUpdate,
        resetForms,
        setIsUpdate,
        isUpdate,
        condicoesPagamento,
        finalizadoras,
        perfis,
        especies,
        lojas,
        disabledFormItens,
        setDisabledFormItens,
        masterDetail,
        setMasterDetail,
        uuidParcela,
        setUuidParcela,
        uuidItens,
        setUuidItens,
        parcelas,
        setParcelas,
        xmlImportado,
        setXmlImportado,
        showFormItens,
        setShowFormItens,
        transmissao,
        setTransmissao,
        oldCodPessoa,
        setOldCodPessoa,
        blockSerieNF,
        setBlockSerieNF,
        setSubTotais,
        subTotais,
      }}
    >
      {children}
    </EntradaNFEContext.Provider>
  );
}

export const useEntradaNFE = () => {
  return useContext(EntradaNFEContext);
};
